.sec_function{
  
  ul{
    display: flex;
    justify-content: space-between;
    li{
      position: relative;
      width: 30%;
      padding: 4% 3.5%;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 3px 20px rgba(0, 123, 205, 0.1);
      .index{
        position: absolute;
        top: 10px;
        left: 30px;
        font-size: 110px;
        line-height: 1;
        font-weight: bold;
        color: #e7f6ff;
        z-index: 0;
      }
      .title{
        position: relative;
        z-index: 10;
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 20px;
      }
      .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        svg{
          width: 100%;
          height: 110px;
        }
      }
    }
  }


	@media screen and (max-width: 750px) {
    ul{
      display: block;
      li{
        width: 100%;
        padding: 10%;
        margin-bottom: 30px;
        .index{
          font-size: 80px;
        }
        .icon{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
          svg{
          }
        }
      }
    }
  }
}