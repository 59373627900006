.c_footer {
  margin-top: 100px;

	.flex-wrap{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 30px;
		margin-bottom: 60px;

		.logo_wrap{
			font-weight: bold;
			.logo{
				width: 130px;
				margin-bottom: 15px;
			}
		}

		.nav_wrap{
			display: flex;
			align-items: center;
			a{
				margin-left: 40px;
			}
		}
	}

	.copyright {
		padding: 10px 0;
		text-align: center;
		background-color: #4a4a4a;
		color: #fff;
		font-size: 13px;
	}



	@media screen and (max-width: 750px) {
		.flex-wrap{
			display: block;
			margin-bottom: 10px;
			padding: 0 15px;

			.logo_wrap{
				font-weight: bold;
				margin-bottom: 20px;
				.logo{
					width: 140px;
					margin-bottom: 5px;
				}
			}
			.nav_wrap{
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				a{
					margin: 0 0 10px 0;
				}
			}
		}
	}
}


.banner_campaign{
  position: fixed;
  bottom: -180px;
  right: 15px;
  z-index: 100;
  transition: 0.3s ease-in-out;
  &.is_display{
    bottom: 15px;
  }

  &:hover{
    bottom: 15px;
  }

	@media screen and (max-width: 750px) {
    display: none;
  }
}


