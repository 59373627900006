@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Oswald:wght@400;600&display=swap");

body {
	font-family: "Noto Sans JP", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #4a4a4a;
}
.font_osw {
	font-family: Oswald, "Noto Sans JP", sans-serif;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
ul,
ol {
	padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
	margin: 0;
}
/* html {
	scroll-behavior: smooth;
} */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.6;
	font-size: 15px;
}
ul,
ol {
	list-style: none;
}
a:not([class]) {
	text-decoration-skip-ink: auto;
}
img,
picture {
	max-width: 100%;
	display: block;
}
article > * + * {
	margin-top: 1em;
}
input,
button,
textarea,
select {
	font: inherit;
}
select,
input[type="text"],
input[type="email"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
}
select::-ms-expand {
	display: none;
}
img:not([alt]) {
	filter: blur(10px);
}
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* -------- */
::selection {
	background: #28e0d2;
	color: #ffffff;
}

/* for Firefox */
::-moz-selection {
	background: #000000;
	color: #ffffff;
}

a {
	text-decoration: none;
	color: #4a4a4a;
	cursor: pointer;
}
p {
	line-height: 2;
}

.all_wrapper {
	padding-top: 100px;
}

.btn_cta {
	color: #fff;
	background: #1595dc;
	background: linear-gradient(160deg, #107ff9 0%, #2fb7ed 50%, #107ff9 100%);
	transition: 0.3s ease-in-out;
	background-size: 100% 120px;
	background-position: top left;
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	display: block;
	width: 350px;
	line-height: 65px;
	border-radius: 50px;
}
.btn_cta:hover {
	background-position: bottom right;
	background-size: 100% 500px;
}

.new-window {
	width: 13px;
	transition: ease-in-out 0.3s;
	vertical-align: -1px;
}
.nav-item {
	position: relative;
	transition: ease-in-out 0.3s;
}
.nav-item:hover {
	color: #1595dc;
}
.nav-item:hover .new-window {
	fill: #1595dc;
}
.nav-item:hover::after {
	transform: scale(1, 1);
	transform-origin: left top;
}
.nav-item::after {
	content: "";
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 2px;
	background: #1595dc;
	transition: transform 0.3s;
	transform-origin: right top;
	transform: scale(0, 1);
}

.text_grade {
	color: #107ff9;
	background: linear-gradient(140deg, #107ff9 0%, #2fb7ed 50%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.text_grade::selection {
	color: #fff;
	background: #28e0d2;
	-webkit-background-clip: initial;
	-webkit-text-fill-color: initial;
}
.sec_title {
	margin-bottom: 100px;
	position: relative;
	text-align: center;
	font-size: 40px;
}
.sec_title::after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -20px;
	transform: translate(-50%);
	height: 2px;
	width: 40px;
	background-color: #107ff9;
}

.title01 {
	font-size: 36px;
	line-height: 1.6;
	margin: 40px 0;
}
.sub01 {
	position: relative;
	padding-left: 70px;
	font-size: 15px;
	font-weight: bold;
	color: #107ff9;
	background: linear-gradient(140deg, #107ff9 0%, #2fb7ed 80%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.sub01::selection {
	color: #fff;
	background: #28e0d2;
	-webkit-background-clip: initial;
	-webkit-text-fill-color: initial;
}
.sub01::before {
	content: "";
	position: absolute;
	top: 52%;
	left: 0;
	width: 50px;
	height: 1px;
	background: linear-gradient(140deg, #107ff9 0%, #2fb7ed 60%);
}

section > .inner {
	max-width: 1200px;
	margin: 0 auto;
	padding: 120px 15px;
}

.page_link{
  background-color: #4a4a4a;
  padding: 15px 15px;
  border-radius: 5px;
}
.page_link a{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}
.page_link svg{
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.p_legal .body {
	padding: 60px;
	background-color: #f3f3f3;
}
.p_legal .body a {
	text-decoration: underline;
}
.p_legal .inner {
	padding: 60px 15px;
}
.p_legal .body section {
	margin-bottom: 30px;
}
.p_legal .body section:last-child {
	margin-bottom: 0;
}
.p_legal ul {
	padding-left: 20px;
	list-style-type: disc;
}
.p_legal li {
	margin-top: 5px;
}
.p_legal .body .head {
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 5px;
}

.p_thanks {
	margin: 70px 0;
	background-color: #f3faff;
	border-radius: 0 150px 0 150px;
	text-align: center;
}
.p_thanks .inner {
	padding: 80px 15px 100px;
}
.p_thanks .sec_title {
	margin-bottom: 60px;
}
.p_thanks .sec_title::after {
	content: none;
}
.p_thanks .icon {
	width: 75px;
	margin: 0 auto 50px;
}
.p_thanks .btn_cta {
	margin: 50px auto 0;
}

@media screen and (min-width: 751px) {
	.pc {
		display: block;
	}
	.sp {
		display: none;
	}
}
@media screen and (max-width: 750px) {
	.pc {
		display: none;
	}
	.sp {
		display: block;
	}
	section > .inner {
		padding: 50px 15px;
	}

	.all_wrapper {
		padding-top: 65px;
	}
	.btn_cta {
		width: 100%;
		background-size: 100% 400px;
	}

	.sec_title {
		margin-bottom: 80px;
		font-size: 28px;
	}
	.sec_title::after {
		bottom: -20px;
		width: 40px;
	}
	.title01 {
		font-size: 26px;
		margin: 10px 0;
	}

	.p_legal .inner {
		padding: 30px 15px;
	}
	.p_legal .body {
		padding: 30px 15px;
	}
}
