.c_header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
  background-color: #fff;
  // box-shadow: 0 3px 20px rgba(0, 123, 205, 0.1);
  
	.wrapper {
    display: flex;
		align-items: center;
    padding: 15px 20px;
    margin-top: 40px;
	}
	.logo{
		width: 130px;
	}
	.nav {
    display: flex;
    flex: 1;
		align-items: center;
		.nav-item {
      display: block;
			margin-left: 40px;
      font-size: 14px;
			font-weight: bold;
		}
		.btn_cta{
			width: 180px;
      margin-left: 20px;
			font-size: 14px;
      border-radius: 100px;
      line-height: 35px;

			&::after{
				content:none;
      }
      &.outline{
        margin-left: auto;
        color: #107ff9;
        border: 1px solid #107ff9;
        background: none;
      }
		}
  }
  
  .sub_nav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 7px 25px 7px 20px;
    border-bottom: 1px solid #d2d2d2;
    font-size: 13px;
    .common_head{
      font-size: 13px;
      font-weight: normal;
    }
    nav{
      display: flex;
      justify-content: flex-end;
    }
    a{
      margin-left: 30px;
      transition: ease-in-out 0.3s;
      &:hover{
        color: #107ff9;
        .new-window{
          fill: #107ff9;
        }
      }
      .new-window{
        margin-left: 5px;
        width: 13px;
        fill: #4a4a4a;
      }
    }
  }

	.hambuger{
		display: none;
		position: relative;
		width: 40px;
    height: 35px;
    margin-left: auto;
		transition: .5s ease-in-out;
		cursor: pointer;
		span{
			position: absolute;
			height: 3px;
			width: 100%;
			vertical-align: middle;
			background: #4a4a4a;
			border-radius: 8px;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: 0.3s ease-in-out;
			padding: 0px;
			&:nth-child(1){
				top: 0px;
			}
			&:nth-child(2){
				top: 15px;
			}
			&:nth-child(3){
				top: 30px;
			}
		}

		&.isOpen{
			span{
				&:nth-child(1){
					top: 30px;
					opacity: 0;
				}
				&:nth-child(2){
					top: 45%;
					transform: rotate(-45deg);
				}
				&:nth-child(3){
					top: 45%;
					transform: rotate(45deg);

				}
			}
		}
	}

	

	@media screen and (max-width: 1030px) {
		&.isOpen::before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, 0.6);
		}
    padding: 15px;

    .wrapper {
      margin: 0;
      padding: 0;
    }
		.logo{
			width: 120px;
			margin-left: 0;
		}
		.nav {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 20px 7vw 0;
			position: fixed;
			top: 0;
			right: -100%;
			width: 80vw;
			height: 100vh;
			background-color: #fff;
			transition: ease-in-out 0.3s;
			.nav_logo{
				display: block;
				margin-bottom: 30px;
				width: 150px;
			}
			&.isOpen{
				right: 0;
			}
			.nav-item {
				margin: 0 0 20px 0;
				display: inline-block;
			}

			.btn_cta{
        width: 100%;
        max-width: 220px;
				margin: 0 0 10px;
				line-height: 40px;
				border-radius: 50px;
	
				&::after{
					content:none;
        }
        &.outline{
          margin-left: 0;
          margin-bottom: 15px;
        }
			}
    }
    .sub_nav{
      position: static;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column-reverse;
      height: auto;
      margin-top: 10px;
      padding: 0;
      font-size: 14px;
      border-bottom: none;
      p{
        font-size: 12px;
        line-height: 1.4;
      }
      nav{
        width: 100%;
        margin-bottom: 15px;
        padding-bottom: 15px;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #d2d2d2;
      }
      a{
        margin-left: 0;
      }
      a:not(:last-child){
        margin: 0 0 15px 0;
      }
      svg{
        margin-left: 3px;
      }
    }
		.hambuger{
			display: block;
		}

		
	}
}
