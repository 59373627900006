.p_flow {
	.inner {
		padding: 60px 15px;
	}

	h3.text_grade {
		margin-bottom: 40px;
		font-size: 26px;
	}
	h3 {
		margin-bottom: 20px;
		font-size: 20px;
	}
	.service {
		margin-bottom: 120px;
	}

	.lead {
		margin-bottom: 10px;
	}

	.caution {
		color: #f6376d;
		font-weight: bold;
		text-decoration: underline;
	}

	.step {
		margin-bottom: 60px;
		li {
			position: relative;
			display: flex;
			align-items: center;
			height: 50px;
			&:not(:last-child) {
				margin-bottom: 55px;
				&::after {
					content: "";
					position: absolute;
					left: 100px;
					bottom: -35px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 12px 0 12px;
					border-color: #2fb7ed transparent transparent transparent;
				}
			}
			h4 {
				width: 210px;
				padding: 7px;
				margin-right: 40px;
				background-color: #2fb7ed;
				border-radius: 50px;
				color: #fff;
				font-size: 18px;
				text-align: center;
			}
		}
	}

	.table {
		margin: 30px 0;
		table {
      width: 100%;
      margin-bottom: 30px;
			border-collapse: separate;
			border-spacing: 0;
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
      text-align: center;
      box-shadow: 0 3px 20px rgba(0, 123, 205, 0.1);
		}
		thead {
			background-color: #2fb7ed;
      color: #fff;
      font-size: 18px;
    }
    th,td{
      padding: 20px;
    }
    tr:not(:last-child){
      td{
        border-bottom: 1px solid #d2d2d2;
      }
    }
  }
  
  .card{
    margin-bottom: 40px;
    h4{
      margin-bottom: 10px;
    }
    ul{
      display: flex;
      li:not(:last-child){
        margin-right: 10px;
      }
    }
  }

	@media screen and (max-width: 750px) {

    .inner{
      padding: 30px 15px;
    }
    h3.text_grade {
      margin-bottom: 30px;
      font-size: 24px;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
    }
    .service {
      margin-bottom: 100px;
    }

    .step {
      margin-bottom: 40px;
      li {
        display: block;
        height: auto;
        &:not(:last-child) {
          margin-bottom: 40px;
          &::after {
            left: 50%;
            bottom: -25px;
            transform: translateX(-50%);
            border-width: 14px 10px 0 10px;
          }
        }
        h4 {
          width: 200px;
          padding: 5px;
          margin-right: 0;
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
    }

    .table {
      margin: 30px 0;
      th,td{
        padding: 10px 5px;
        font-size: 12px;
      }
    }

    .card{
      margin-bottom: 30px;
      h4{
        margin-bottom: 10px;
      }
      ul{
        flex-wrap: wrap;
        li{
          width: 60px;
          margin-bottom: 10px;
        }
        li:not(:last-child){
          margin-right: 10px;
        }
      }
    }
	}
}
