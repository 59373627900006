.sec_faq {
	.faq_wrap {
		border-top: 1px solid #d2d2d2;

		&:last-child {
			border-bottom: 1px solid #d2d2d2;
		}

		.q_wrap {
			position: relative;
      display: flex;
      padding: 40px 50px;
      
			cursor: pointer;

			&::after {
				content: "";
				position: absolute;
				right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
				width: 30px;
				background-color: #107ff9;
				transition: ease-in-out 0.3s;
			}
			&::before {
				content: "";
				position: absolute;
				right: 0;
        top: 50%;
        transform: translateY(-50%);
				height: 2px;
				width: 30px;
				background-color: #107ff9;
				transform: translateY(-50%) rotate(90deg);
				transition: ease-in-out 0.3s;
			}
		}
		.question {
			font-size: 20px;
			font-weight: bold;
			line-height: 1.2;
		}
		.index {
			width: 63px;
			font-weight: bold;
			line-height: 1;
			color: #107ff9;
			font-size: 22px;
		}

		.answer {
			max-height: 0;
			visibility: hidden;
			opacity: 0;
			padding: 0 110px;
			transition: all 0.5s;
			margin-top: 0;
		}
		a {
			color: #107ff9;
		}
	}

	.faq_wrap.is_open {
		.q_wrap {
			&::after {
				right: 0;
				background-color: #107ff9;
				transform: rotate(180deg);
			}
			&::before {
				right: 0;
				background-color: #107ff9;
				transform: rotate(200deg);
				opacity: 0;
			}
		}
		.answer {
			max-height: 200px;
			visibility: visible;
			opacity: 1;
			padding-bottom: 40px;
		}
	}



	@media screen and (max-width: 750px) {
    .faq_wrap {
      .q_wrap {
        padding: 20px 0;
        &::after {
          width: 20px;
        }
        &::before {
          width: 20px;
        }
      }

      .index {
        width: 30px;
        font-size: 20px;
      }
      .question{
        font-size: 18px;
        flex: 1;
        margin-right: 25px;
      }
      .answer {
        padding: 0;
        line-height: 1.5;
      }
    }


    .faq_wrap.is_open {
      .answer {
        padding-bottom: 20px;
        line-height: 1.5;
      }
    }
	}
}
