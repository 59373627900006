.sec_form {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 280px;
		left: 0;
		z-index: -1;
		height: 50%;
		width: 95%;
		background-color: #f3faff;
		border-radius: 0 150px 0 150px;
	}

	.inner {
		max-width: 1000px;
	}
	.sec_title {
		margin-bottom: 160px;
	}

	.lottie{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 50px;
		height: 50px;
		path{
			stroke: #2fb7ed;
			fill: #2fb7ed;
		}
	}

	.c_form {
		padding: 80px;
		background-color: #fff;
		box-shadow: 0 3px 20px rgba(0, 123, 205, 0.1);
		border-radius: 10px;
		overflow: hidden;
	}

	.mark_require {
		color: #da2121;
		font-size: 12px;
	}
	.btn_cta {
		outline: none;
		border: none;
		margin: 0 auto;
		cursor: pointer;
		&.disabled {
			-webkit-filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			-ms-filter: grayscale(100%);
			filter: grayscale(100%);
		}
	}

	ul {
		margin-bottom: 60px;
	}
	li {
		position: relative;
		margin-top: 40px;
		&:first-child {
			margin-top: 0;
		}
		.flex {
      display: flex;
		}

		label {
			flex-basis: 200px;
			padding-top: 5px;
		}
		input,
		select,
		textarea {
			flex: 1;
			border: 1px solid #e5e5e5;
			padding: 7px 40px 7px 10px;
			font-size: 16px;
			background-color: #f9f9f9;
			border-radius: 5px;
		}
		textarea {
			min-height: 300px;
		}

		.error_text {
			position: absolute;
			bottom: -30px;
			left: 200px;
			font-size: 13px;
			color: #da2121;
		}
	}

	.list_plan .flex{
		position: relative;
		&::after{
			content: "▼";
			position: absolute;
			right: 20px;
			bottom: 23px;
			width: 10px;
			height: 10px;
		}
	}

	.check_wrap {
		margin-bottom: 30px;
		text-align: center;
		a {
			color: #1595dc;
			text-decoration: underline;
		}
	}

	.recaptcha{
		margin-bottom: 60px;
		text-align: center;
		div{
			display: inline-block;
		}
	}
}

@media screen and (max-width: 750px) {
	.sec_form {
		&::before {
			top: 150px;
			border-radius: 0 80px 0 80px;
		}

		.sec_title {
			margin-bottom: 100px;
		}

		.lottie{
			top: -10px;
			transform: none;
		}

		.c_form {
			padding: 40px 20px;
		}

		ul {
			margin-bottom: 60px;
		}

		li {
			margin-top: 30px;
			.flex {
				display: block;
			}

			label {
				display: block;
				margin-bottom: 10px;
				font-size: 15px;
			}
			input,
			select,
			textarea {
				width: 100%;
			}
			textarea {
				min-height: 250px;
			}

			.error_text {
				bottom: -30px;
				left: 0;
				font-size: 13px;
			}
		}
		.list_type .flex {
			display: flex;
      flex-wrap: wrap;
      flex-direction: column;
			label {
				&:first-child{
					flex-basis: 100%;
				}
				flex-basis: 40%;
			}
			input {
				width: auto;
				padding: 0;
			}
		}

		.check_wrap {
			margin-bottom: 30px;
			text-align: center;
			a {
				color: #1595dc;
				text-decoration: underline;
			}
		}
	}
}
