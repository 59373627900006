.sec_about{
  .flex_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3faff;
    
    &:nth-child(1){
      padding: 140px 15px 280px;
      border-radius: 0 150px 0 0;
    }
    &:nth-child(2){
      padding: 140px 15px 280px;
      margin-top: -120px;
      border-radius: 0 150px 0 0;
      background-color: #fff;
    }
    &:nth-child(3){
      padding: 140px 15px ;
      margin-top: -120px;
      border-radius: 0 150px 0 150px;
      img{
        width: 85%;
      }
    }

    &:nth-child(even){
      flex-direction: row-reverse;
      .text{
        margin: 0 80px 0 0;
      }
    }
    .img{
      img{
        margin: 0 auto;
      }
    }
    .text{
      width: 55%;
      max-width: 500px;
      margin: 0 0 0 100px;
    }
  }


  @media screen and (max-width: 750px) {
    .flex_wrap{
      display: block;

      &:nth-child(1){
        padding: 80px 15px 160px;
        border-radius: 0 80px 0 0;
      }
      &:nth-child(2){
        padding: 80px 15px 160px;
        margin-top: -80px;
        border-radius: 0 80px 0 0;
      }
      &:nth-child(3){
        padding: 80px 15px ;
        margin-top: -80px;
        border-radius: 0 80px 0 80px;
        img{
          width: 60%;
        }
      }
      img{
        margin: 0 auto;
      }
  
      &:nth-child(even){
        .text{
          margin: 30px 0 0;
        }
      }
      .img{
        max-width: inherit;
        width: 80%;
        margin: 0 auto;
      }
      .text{
        max-width: inherit;
        margin: 30px 0 0;
        width: auto;
      }
    }
  }
}