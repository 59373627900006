.sec_plan{
  position: relative;
  padding-bottom: 100px;

  .inner{
    position: relative;
    padding: 100px 15px 160px;
  }
  .flex_wrap{
    display: flex;
    justify-content: space-between;
  }
  .card{
    width: 47%;
    margin-bottom: 80px;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 123, 205, 0.1);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;

    .head{
      padding: 30px;
      .target{
        border-radius: 25px;
        background-color: #fff;
        display: inline-block;
        padding: 3px 30px;
        font-weight: bold;
        font-size: 18px;
      }
    }
    h3{
      color: #fff;
      font-size: 20px;
      line-height: 1;
    }
    h4{
      margin-bottom: 10px;
      color: #fff;
      font-size: 34px;
    }
    .small{
      color: #fff;
      font-size: 20px;
    }
    .label{
      font-size: 14px;
      color: #888;
    }
    .line_through{
      font-size: 18px;
      text-decoration: line-through;
      &+span{
        color: #f6376d;
      }
    }

    &:nth-child(1){
      li{
        p{color: #2fb7ed;}
      }
      .head{background-color: #2fb7ed;}
      .target{color: #2fb7ed;}
    }
    &:nth-child(2){
      li{
        p{color: #107ff9;}
      }
      .head{background-color: #107ff9;}
      .target{color: #107ff9;}
    }
      
    ul{padding: 0 20px;}
    li{
      padding: 10px 0 25px;
      font-size: 22px;
      font-weight: bold;
      border-bottom: 1px solid #d2d2d2;
      &:last-child{
        border-bottom: none;
      }
      p{
        line-height: 1.2;
      }
    }
  }

  .page_link{
    position: absolute;
    left: 15px;
    bottom: 60px;
    z-index: 2;
  }

  .scale_wrap{
    position: relative;
    max-width: 800px;
    width: 65vw;
    z-index: 10;
    padding: 40px 60px;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 123, 205, 0.1);
    border-radius: 10px;
    overflow: hidden;
    .flex_wrap{
      align-items: center;
    }
    .title01{
      margin: 20px 0;
    }
    p{
      font-weight: bold;
    }
    .img{
      width: 220px;
      img{
        margin: 0 auto;
      }
    }
  }

  .bg{
    position: absolute;
    bottom: -60px;
    right: 0;
    width: 55%;
    max-width: 720px;
    z-index: 1;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


	@media screen and (max-width: 750px) {
    padding-bottom: 120px;
    .inner{
      padding: 0 15px 160px;
    }
    .flex_wrap{
      display: block;
      .card{
        width: auto;
        margin-bottom: 40px;
        .head{
          padding: 20px;
          .target{
            padding: 0px 30px;
            font-weight: bold;
            font-size: 16px;
          }
        }
        h4{
          margin-bottom: 5px;
          font-size: 30px;
        }
        .small{
          font-size: 16px;
        }
        .label{
          font-size: 13px;
        }

        li{
          padding: 5px 0 15px;
          font-size: 20px;
          p{
            line-height: 1.2;
          }
        }
      }
    }

    .scale_wrap{
      padding: 20px;
      width: auto;
      .img{
        width: 60%;
        margin: 20px auto 0;
      }
    }
    .page_link{
      left: 50%;
      transform: translateX(-50%);
      bottom: 70px;
      width: 260px;
      z-index: 2;
    }
    .bg{
      bottom: -10px;
      right: 0;
      width: 100%;
      height: 70vw;
      z-index: 1;
    }
  }
}