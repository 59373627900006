.sec_mv{
  position: relative;
  padding: 60px 0 150px;
  max-width: 1400px;
  margin: 0 auto;
  .text_wrap{
    position: relative;
    z-index: 11;
    padding-left: 30px;
    h2{
      margin-bottom: 50px;
      font-size: 60px;
    }
    p{
      margin-bottom: 50px;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .img_wrap{
    position: absolute;
    right: 30px;
    top: 45%;
    transform: translate(0, -50%);
    z-index: 10;
    width: 55vw;
    img{
      width: 100%;
      max-width: 730px;
      margin-left: auto;
    }
  }


	@media screen and (max-width: 750px) {
    padding: 290px 15px 80px;
    .text_wrap{
      padding-left: 0;
      h2{
        margin-bottom: 20px;
        font-size: 32px;
      }
      p{
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .img_wrap{
      right: 50%;
      top: 0;
      transform: translate(50%, 0);
      width: 95vw;
      img{
        width: 100%;
        max-width: 400px;
        margin: auto;
      }
    }
  }
}